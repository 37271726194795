import { Box, Drawer, Paper, Skeleton, Tab, Tabs, useTheme } from '@mui/material';
import { useSignals } from '@preact/signals-react/runtime';
import { find, first, map } from 'lodash';
import React from 'react';
import { BooleanParam, useQueryParam } from 'use-query-params';

import { Accession } from 'interfaces/accession';
import { Procedure } from 'interfaces/procedure';
import { getSlideThumbnailUrl } from 'utils/helpers';
import { useCurrentLabId } from 'utils/useCurrentLab';
import { useSelectedSlideIds } from 'utils/useCurrentSlideIds';
import { updateZoomFactorFromSlide } from '../zoomFactor';
import { ThumbnailsSlider } from './ThumbnailsSlider';
import { useThumbnailSlides } from './useThumbnailSlides';

const SlideThumbnail: React.FC<
  React.PropsWithChildren<{
    index: number;
    url?: string;
    height: string | number;
    onClick?: () => void;
    selected?: boolean;
  }>
> = ({ url, index, onClick, height, selected }) => (
  <Tab
    value={index}
    LinkComponent={Paper}
    sx={{
      backgroundColor: 'transparent',
      height,
      width: height,
      opacity: 1,
      pointer: !onClick ? 'not-allowed' : 'pointer',
      padding: 1,

      '&>div': {
        border: 1,
        boxShadow: 1,
        borderRadius: 1,
        height: '100%',
        width: '100%',
        backgroundImage: url ? `url(${url})` : undefined,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        backgroundColor: '#f5f5f5',

        '&:hover': !selected ? { boxShadow: 0 } : undefined,
        'a:hover': { color: 'inherit' },
      },
    }}
    onClick={onClick}
    icon={
      url ? (
        <div className={selected ? 'selected-thumbnail' : undefined} />
      ) : (
        <Skeleton variant="rectangular" width="100%" height="100%" />
      )
    }
  />
);

export const SlideThumbnails: React.FC<
  React.PropsWithChildren<{
    caseData: Procedure | Accession;
    height: string | number;
    marginLeft?: string | number;
    marginRight?: string | number;
    marginTop?: string | number;
    coverOtherContents?: boolean;
    isLoadingCaseData?: boolean;
  }>
> = ({ caseData, isLoadingCaseData, height, coverOtherContents, marginLeft, marginRight, marginTop = '2px' }) => {
  useSignals();
  const theme = useTheme();
  const [showSlidesThumbnails, setShowSlidesThumbnails] = useQueryParam('showSlidesThumbnails', BooleanParam);
  const { labId } = useCurrentLabId();

  const [selectedSlideIds] = useSelectedSlideIds(caseData);

  const selectedSlideId = first(selectedSlideIds) ?? first(caseData?.slides)?.id;

  const { isLoading, currentImageIndex, changeImageIndex, caseSlideThumbnailsData } = useThumbnailSlides(
    caseData,
    selectedSlideId,
    {
      enabled: isLoadingCaseData,
    }
  );
  const primarySlide = find(caseData.slides, { id: selectedSlideId });

  const [useDeckGL] = useQueryParam('useDeckGL', BooleanParam);

  return (
    <>
      {/* Buffer to push the viewer up */}
      {!coverOtherContents && showSlidesThumbnails && <Box sx={{ height, marginLeft, marginRight }} />}
      <Drawer
        anchor="bottom"
        open={showSlidesThumbnails}
        variant="persistent"
        sx={{
          overflow: 'hidden',
          '& .MuiTabs-indicator': {
            height: `calc(${height} - ${marginTop})`,
            zIndex: -1,
            opacity: 0,
          },
          '&>.MuiPaper-root': {
            backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[50] : theme.palette.grey[500],
            height: `calc(${height} - ${marginTop})`,
            marginLeft,
            marginRight,
            marginTop,

            '& .selected-thumbnail': {
              color: 'primary.main',
              boxShadow: '0px 0px 0px 2px',
            },
          },
        }}
        onClose={!useDeckGL ? () => updateZoomFactorFromSlide(primarySlide, useDeckGL) : undefined}
      >
        <Tabs scrollButtons="auto" variant="scrollable" value={Math.max(0, currentImageIndex)}>
          {map(caseSlideThumbnailsData, (slideThumbnailData, index) => {
            const url = getSlideThumbnailUrl({
              id: slideThumbnailData.slideId,
              labId: slideThumbnailData?.labId || labId,
            });
            return (
              <SlideThumbnail
                key={`${url}||${index}`}
                url={!isLoading && showSlidesThumbnails ? url : undefined}
                index={index}
                selected={index === currentImageIndex}
                height={`calc(${height} - ${marginTop})`}
                onClick={() => changeImageIndex(index)}
              />
            );
          })}
        </Tabs>
      </Drawer>
      <ThumbnailsSlider
        drawerHeight={height}
        onClick={() => setShowSlidesThumbnails(!showSlidesThumbnails)}
        collapsed={!showSlidesThumbnails}
        marginLeft={marginLeft}
        marginRight={marginRight}
        text={!showSlidesThumbnails ? 'Show Slides' : ''}
      />
    </>
  );
};
