import { useQuery } from '@tanstack/react-query';
import { filter, find, includes, isEmpty, map } from 'lodash';
import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { StringParam, useQueryParam } from 'use-query-params';

import { getAccession, getAccessions } from 'api/accessions';
import { getStudyProcedure, getStudyProcedureQueryKey } from 'api/study';
import { RouteProps } from 'components/Auth/PrivateRoute';
import { Accession } from 'interfaces/accession';
import { Permission } from 'interfaces/permissionOption';
import { Procedure } from 'interfaces/procedure';
import { Slide } from 'interfaces/slide';
import { useCurrentLabId } from 'utils/useCurrentLab';
import { useEncodedFilters, useFiltersForAccessions } from 'utils/useEncodedFilters';
import { usePermissions } from 'utils/usePermissions';
import { useProceduresWithQAExperimentResultsOnly } from 'utils/useProceduresWithQAExperimentResultsOnly';
import { CaseView } from './CaseView';
import NotFoundMessage from './NotFoundMessage';
import { usePrefetchedCaseData } from './usePrefetchedCaseData';

export const INFOBAR_PIXEL_WIDTH = 370;
export const INFOBAR_WIDTH = `${INFOBAR_PIXEL_WIDTH}px`;
export const SLIDES_THUMBNAILS_HEIGHT = '110px';

const ProcedureLoader: React.FunctionComponent<React.PropsWithChildren<RouteProps>> = (props) => {
  const params = useParams();
  const procedureId = Number(params.id);

  const { data: filteredCases } = useProceduresWithQAExperimentResultsOnly({
    pageRadius: 1,
  });
  const currentCaseFromSearch = find(filteredCases?.procedures, {
    id: procedureId,
  });

  const { queryParams, encodedFilters } = useEncodedFilters();

  const [fromStudyFilter, setFromStudyFilter] = useQueryParam('fromStudyFilter', StringParam);
  const inferredStudyId =
    params?.studyId || currentCaseFromSearch?.studyId || queryParams.filters?.studyId || fromStudyFilter;

  const {
    data,
    isLoading,
    isError,
    isPlaceholderData,
    refetch: refetchProcedure,
  } = useQuery(
    // this key is without the studyId because for now the backend fetches the case's studyId by itself.
    // and we can also be in a case page with studyId = ALL / NONE, so we don't want to refetch the case
    // this should be changed in the future
    getStudyProcedureQueryKey(inferredStudyId, procedureId, queryParams),
    ({ signal }) => getStudyProcedure(inferredStudyId, procedureId, encodedFilters, signal),
    {
      placeholderData: currentCaseFromSearch ? { procedure: currentCaseFromSearch } : undefined,
      enabled: procedureId && !isNaN(procedureId),
    }
  );

  React.useEffect(() => {
    const actualStudyId = data?.procedure?.studyId || inferredStudyId;
    if (actualStudyId && fromStudyFilter !== actualStudyId) {
      setFromStudyFilter(actualStudyId, 'replaceIn');
    }
  }, [data?.procedure?.studyId, inferredStudyId, fromStudyFilter, setFromStudyFilter]);

  const { caseData: prefetchedCaseData } = usePrefetchedCaseData({
    caseId: procedureId,
    studyId: data?.procedure?.studyId || inferredStudyId,
  });

  const caseData: Procedure = data?.procedure || prefetchedCaseData;

  const { hasPermission } = usePermissions();
  const canAccessAllLabs = hasPermission(Permission.ApplyPermissionsAcrossLabs);

  const { labId, setLabId } = useCurrentLabId();
  const shouldChangeLab = canAccessAllLabs && !isLoading && caseData?.labId && caseData?.labId !== labId;
  React.useEffect(() => {
    if (shouldChangeLab && caseData?.labId) {
      console.info('Changing lab to', caseData?.labId, 'from', labId);
      setLabId(caseData?.labId);
    }
  }, [shouldChangeLab, caseData?.labId]);

  const loadingFailed = !isLoading && (isError || isEmpty(caseData?.slides));
  return loadingFailed && !shouldChangeLab ? (
    <NotFoundMessage />
  ) : (
    <CaseView
      procedure={caseData}
      refetchProcedure={refetchProcedure}
      isAccessionViewer={false}
      isPlaceholderData={isPlaceholderData || !data?.procedure}
      isLoadingCaseData={isLoading}
      {...props}
    />
  );
};

export const AccessionLoader: React.FunctionComponent<React.PropsWithChildren<RouteProps>> = (props) => {
  const params = useParams();
  const procedureId = Number(params.id);

  const { encodedFilters } = useFiltersForAccessions();

  const { data: accessions } = useQuery(['accessions', encodedFilters], () => getAccessions(encodedFilters));

  const currentAccessionFromSearch = find(accessions?.accessions, { id: procedureId });

  const { labId, setLabId } = useCurrentLabId();

  const {
    data,
    isLoading,
    isError,
    isPlaceholderData,
    refetch: refetchProcedure,
  } = useQuery(['accession', procedureId], () => getAccession(procedureId), {
    placeholderData: currentAccessionFromSearch,
  });

  const caseData: Accession = useMemo(() => {
    if (!data) {
      return {
        id: procedureId,
        slides: [],
        labId,
        cancerTypeId: undefined,
        studyId: undefined,
        accessionData: {
          procedureId,
          patientId: undefined,
          accessionId: undefined,
          patient: undefined,
        },
      };
    }

    const filteredSlideIds = map(currentAccessionFromSearch?.slides, 'id');

    return {
      ...data,
      slides: filter(data.slides, (slide: Slide) => includes(filteredSlideIds, slide.id)),
    };
  }, [data, currentAccessionFromSearch?.slides]);

  const { hasPermission } = usePermissions();
  const canAccessAllLabs = hasPermission(Permission.ApplyPermissionsAcrossLabs);

  const shouldChangeLab = canAccessAllLabs && !isLoading && caseData?.labId && caseData?.labId !== labId;
  React.useEffect(() => {
    if (shouldChangeLab && caseData?.labId) {
      setLabId(caseData?.labId);
    }
  }, [shouldChangeLab, caseData?.labId]);

  const loadingFailed = !isLoading && (isError || isEmpty(caseData?.slides));
  return loadingFailed && !shouldChangeLab ? (
    <NotFoundMessage />
  ) : (
    <CaseView
      procedure={caseData}
      refetchProcedure={refetchProcedure}
      isAccessionViewer
      isPlaceholderData={isPlaceholderData || !data}
      isLoadingCaseData={isLoading}
      {...props}
    />
  );
};

export const ProcedurePage: React.FunctionComponent<React.PropsWithChildren<RouteProps>> = (props) => {
  const { hasPermission } = usePermissions();
  const isAccessionViewer = hasPermission(Permission.ViewAccessionDashboard);
  return isAccessionViewer ? <AccessionLoader {...props} /> : <ProcedureLoader {...props} />;
};

export default ProcedurePage;
