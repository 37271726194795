import { useQuery } from '@tanstack/react-query';
import { getCaseSlideThumbnailsData, getProcedureIds } from 'api/procedures';
import { getSlidesViewerNavigationPrefetchData } from 'api/slides';
import { DEFAULT_PAGE_SIZE } from 'components/StudyDashboard/ProceduresPage/ProcedurePagination';
import { SlidePrefetchData } from 'interfaces/slide';
import { filter, find, flatMap, isEmpty, map, max, some } from 'lodash';
import { useMemo } from 'react';
import { BooleanParam, useQueryParam } from 'use-query-params';
import { ExperimentResultsSelection, useEncodedFilters } from 'utils/useEncodedFilters';
import { usePaginatedQueries } from 'utils/usePaginatedQueries';

export const useFilteredCaseIds = (caseId: number, studyId?: string) => {
  const { generateEncodedParams, queryParams } = useEncodedFilters({
    experimentResultsSelection: ExperimentResultsSelection.OnlyQAFailed,
  });

  const idsEncodedParams = generateEncodedParams({
    filters: {
      ...queryParams?.filters,
      ...(studyId && isEmpty(queryParams?.filters) ? { studyId: studyId } : {}),
    },
    // The page is not important, the response return all the ids
    page: 1,
    pageSize: DEFAULT_PAGE_SIZE,
  });

  return useQuery(['procedureIds', [idsEncodedParams]], {
    queryFn: ({ signal }) =>
      studyId ? getProcedureIds(idsEncodedParams) : getProcedureIds(idsEncodedParams, caseId, signal),
    keepPreviousData: true,
  });
};

export const useFilteredCaseSlideThumbnailsData = ({
  studyId,
  enabled = true,
}: { studyId?: string; enabled?: boolean } = {}) => {
  const { generateEncodedParams, queryParams } = useEncodedFilters({
    experimentResultsSelection: ExperimentResultsSelection.OnlyQAFailed,
  });

  const idsEncodedParams = generateEncodedParams({
    filters: {
      ...queryParams?.filters,
      ...(studyId && !queryParams?.filters?.studyId ? { studyId: studyId } : {}),
    },
    // The page / pageSize is not important, the response return all the ids
    page: 1,
    pageSize: DEFAULT_PAGE_SIZE,
  });

  return useQuery(['slideThumbnailsData', [idsEncodedParams]], {
    queryFn: ({ signal }) => getCaseSlideThumbnailsData(idsEncodedParams, signal),
    keepPreviousData: true,
    enabled,
  });
};

export const useFilteredSlidesViewerNavigationPrefetchData = ({
  studyId,
  enabled = true,
  numPagesToPrefetch = 1,
}: {
  studyId?: string;
  enabled?: boolean;
  numPagesToPrefetch?: number;
} = {}): {
  data: SlidePrefetchData[];
  isLoading: boolean;
  isInitialLoading: boolean;
} => {
  const [pendingSlidesMode] = useQueryParam('pendingSlidesMode', BooleanParam);

  const { generateEncodedParams, queryParams } = useEncodedFilters({
    experimentResultsSelection: ExperimentResultsSelection.OnlyQAFailed,
  });

  const currentPage = queryParams.page || 1;
  const pageSize = max([queryParams.pageSize || DEFAULT_PAGE_SIZE, 1]);
  const filters = {
    ...queryParams?.filters,
    ...(studyId && !queryParams?.filters?.studyId ? { studyId: studyId } : {}),
  };
  const allSlideThumbnailsQuery = useFilteredCaseSlideThumbnailsData({ studyId, enabled });
  const slideThumbnailsData = filter(allSlideThumbnailsQuery?.data, ({ caseId }) =>
    pendingSlidesMode ? caseId == null : caseId !== null
  );
  const totalSlides = slideThumbnailsData?.length || 0;
  const totalPages = Math.ceil(totalSlides / pageSize);
  const paginatedQueries = usePaginatedQueries({
    pageQueryFn: (pageArgs, { signal }) => getSlidesViewerNavigationPrefetchData(pageArgs, signal),
    getPageArgs: (page) =>
      generateEncodedParams(
        { filters, page: page + 1, pageSize },
        { pendingSlidesMode },
        { pendingSlidesMode: BooleanParam }
      ),
    getQueryKeyForPage: (page) => [
      'slidesViewerNavigationPrefetchData',
      generateEncodedParams(
        { filters, page: page + 1, pageSize },
        { pendingSlidesMode },
        { pendingSlidesMode: BooleanParam }
      ),
    ],
    currentPage,
    totalPages,
    numPagesToPrefetch,
    getItemsFromPageData: (data) => data,
    getPlaceholderItems: () => [],
  });

  const data = useMemo(() => {
    const allPaginatedItems = flatMap(paginatedQueries.items, ({ items }) => items);
    return map(
      slideThumbnailsData,
      (caseThumbnailsData): SlidePrefetchData =>
        find(allPaginatedItems, {
          caseId: caseThumbnailsData.caseId,
          slideId: caseThumbnailsData.slideId,
        }) || caseThumbnailsData
    );
  }, [paginatedQueries.items, slideThumbnailsData]);

  const isLoading =
    allSlideThumbnailsQuery.isLoading || some(paginatedQueries.queries, (query) => query.isLoading && query.isFetching);
  const isInitialLoading =
    allSlideThumbnailsQuery.isInitialLoading || some(paginatedQueries.queries, 'isInitialLoading');

  const res = {
    data,
    isLoading,
    isInitialLoading,
  };

  return res;
};
