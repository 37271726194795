import { BooleanParam, DelimitedArrayParam, JsonParam, StringParam } from 'use-query-params';

import { annotationAssignmentIdViewerQuerySchema } from 'services/annotations/useAnnotationQueryParams';

export const additionalNavigationParamsSchema = {
  slideQualityActionsDrawerOpen: BooleanParam,
  showAllJobsInCasePage: BooleanParam,
  slideIdForReviewing: StringParam,
  showSlidesThumbnails: BooleanParam,
  slideTagsDrawerOpen: BooleanParam,
  useDeckGL: BooleanParam,
  fullscreenViewer: BooleanParam,
  reviewTab: StringParam,
  fromStudyFilter: StringParam,
  annotationsListVisibility: StringParam,
  stainToBaseSlideSettings: JsonParam,
  layerSettingsPerStainAndDisplayName: JsonParam,
  markerChannelColorSettings: JsonParam,
  markerChannelToggle: JsonParam,
  ...annotationAssignmentIdViewerQuerySchema,
  viewersStainPreferences: DelimitedArrayParam,
};
