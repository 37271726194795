import { GridRowId, GridRowModesModel } from '@mui/x-data-grid';
import { GridApiCommunity } from '@mui/x-data-grid/internals';
import { useQueryClient } from '@tanstack/react-query';
import { createTag, deleteTag, updateTag } from 'api/taxonomyTags';
import { useCrudControlsColumns } from 'components/atoms/EditableDataGrid/rowEditingControlsColumns';
import { TaxonomyTag } from 'interfaces/taxonomyTag';
import { reject } from 'lodash';
import React from 'react';
import { useMutationWithErrorSnackbar } from 'utils/useMutationWithErrorSnackbar';
import { taxonomyTagFields } from './taxonomyTagFields';

import { TaxonomyTagRowChangesSummary } from './TaxonomyTagRowChangesSummary';
import { TaxonomyTagDraft } from './types';
import { getTaxonomyTagError, getTaxonomyTagId } from './utils';

export const useTaxonomyTagColumns = ({
  apiRef,
  noRows,
  taxonomyTags,
  draftTaxonomyTags,
  rowModesModel,
  setDraftTaxonomyTags,
  setRowModesModel,
}: {
  noRows?: boolean;
  apiRef: React.MutableRefObject<GridApiCommunity>;
  taxonomyTags: TaxonomyTag[];
  draftTaxonomyTags: TaxonomyTagDraft[];
  rowModesModel: GridRowModesModel;
  setDraftTaxonomyTags: React.Dispatch<React.SetStateAction<Array<Omit<TaxonomyTag, 'index'>>>>;
  setRowModesModel: React.Dispatch<React.SetStateAction<GridRowModesModel>>;
}) => {
  const [mutatingRowId, setMutatingRowId] = React.useState<GridRowId | undefined>();
  const commonMutationOptions = React.useMemo(() => ({ onError: () => setMutatingRowId(undefined) }), []);

  const queryClient = useQueryClient();

  const createTaxonomyTagMutation = useMutationWithErrorSnackbar({
    ...commonMutationOptions,
    onSuccess: (newTaxonomyTag) => {
      queryClient.invalidateQueries(['taxonomyTags']);
      queryClient.setQueryData(['taxonomyTags'], (oldData: TaxonomyTag[]) => [...oldData, newTaxonomyTag]);
    },
    mutationFn: createTag,
    mutationDescription: 'create taxonomy tag',
  });

  const updateTaxonomyTagMutation = useMutationWithErrorSnackbar({
    ...commonMutationOptions,
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries(['taxonomyTags']);
      queryClient.setQueryData(['taxonomyTags'], (oldData: TaxonomyTag[]) => {
        return [...reject(oldData, { id: mutatingRowId }), variables];
      });
    },
    mutationFn: updateTag,
    mutationDescription: 'update taxonomy tag',
  });

  const deleteTaxonomyTagMutation = useMutationWithErrorSnackbar({
    ...commonMutationOptions,
    onSuccess(data, variables) {
      queryClient.invalidateQueries(['taxonomyTags']);
      queryClient.setQueryData(['taxonomyTags'], (oldData: TaxonomyTag[]) => reject(oldData, { id: variables }));
    },
    mutationFn: deleteTag,
    mutationDescription: 'delete taxonomy tag',
  });

  const columns = useCrudControlsColumns<TaxonomyTag, TaxonomyTagDraft>({
    createMutation: createTaxonomyTagMutation,
    deleteMutation: deleteTaxonomyTagMutation,
    updateMutation: updateTaxonomyTagMutation,
    apiRef,
    rowModesModel,
    setRowModesModel,
    setDraftRows: setDraftTaxonomyTags,
    draftRows: draftTaxonomyTags,
    currentRows: taxonomyTags,
    rowTypeFields: taxonomyTagFields,
    getRowError: getTaxonomyTagError,
    noRows,
    getCancelEditConfirmationModalOptions: ({ id, newRowValue, isDraft, changes }) => ({
      title: `Cancel taxonomy tag ${isDraft ? 'creation' : 'update'}`,
      text: (
        <TaxonomyTagRowChangesSummary
          taxonomyTagDisplayString={`Taxonomy Tag "${newRowValue?.displayName}" (${newRowValue?.id ?? id})`}
          changes={changes}
        />
      ),
    }),
    getSaveConfirmationModalOptions: ({ id, newRowValue, isDraft, changes }) => ({
      title: `${isDraft ? 'Create' : 'Update'} taxonomy tag`,
      text: (
        <TaxonomyTagRowChangesSummary
          taxonomyTagDisplayString={`Taxonomy Tag "${newRowValue?.displayName}" (${newRowValue?.id ?? id})`}
          changes={changes}
        />
      ),
    }),
    getDeleteConfirmationModalOptions: () => ({
      title: 'Delete Taxonomy Tag',
      text: 'Are you sure you want to delete this taxonomy tag?',
    }),
    idGetter: getTaxonomyTagId,
    draftIdField: 'draftId',
    mutatingRowId,
    setMutatingRowId,
  });

  return columns;
};
