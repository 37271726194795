import { usePrefetchedCaseData } from 'components/Procedure/usePrefetchedCaseData';
import { DEFAULT_PAGE_SIZE } from 'components/StudyDashboard/ProceduresPage/ProcedurePagination';
import { Procedure } from 'interfaces/procedure';
import { Slide } from 'interfaces/slide';
import { findIndex } from 'lodash';
import React from 'react';
import { useGetDefaultSelectedSlideIds } from 'utils/useCurrentSlideIds';
import { ExperimentResultsSelection, useEncodedFilters } from 'utils/useEncodedFilters';
import { useNavigationToViewerPage } from 'utils/useNavigationToViewerPage';
import { BaseNavigationPanel } from './BaseNavigationPanel';
import { useCaseIndexesInFilter } from './useCaseIndexesInFilter';

export const CaseNavigationPanel: React.FunctionComponent<
  React.PropsWithChildren<{
    isLoadingCaseData: boolean;
    currentCase: Procedure;
    studyId?: string;
  }>
> = ({ currentCase, studyId, isLoadingCaseData }) => {
  const currentCaseId = currentCase?.id;
  const { isInitialLoadingCaseIds, caseIds, nextCaseId, previousCaseId, currentCaseIndex, totalCases } =
    useCaseIndexesInFilter(currentCaseId, studyId);
  const { queryParams, setQueryParams } = useEncodedFilters({
    experimentResultsSelection: ExperimentResultsSelection.OnlyQAFailed,
  });

  // The default value is for the case that somehow the navigation list is empty but we still have case data and the page is loaded
  let navigationStageText: string | null = '1/1';
  if (currentCaseIndex !== -1 && totalCases > 0) {
    navigationStageText = `${currentCaseIndex + 1} / ${totalCases} cases`;
  }

  const pageSize = queryParams.pageSize || DEFAULT_PAGE_SIZE;

  const getCaseIdPage = ({ targetCaseId }: { targetCaseId?: number }) => {
    return caseIds ? Math.floor(findIndex(caseIds, (id) => id === targetCaseId) / pageSize) + 1 : null;
  };

  const { getUrlToCasePage } = useNavigationToViewerPage();

  const getDefaultSelectedSlideIds = useGetDefaultSelectedSlideIds(studyId);

  const getCasePageUrl = ({ caseId, prefetchedSlides }: { caseId?: number; prefetchedSlides?: Slide[] }) => {
    return getUrlToCasePage({
      caseId,
      caseStudyId: queryParams?.filters?.studyId,
      newPage: getCaseIdPage({ targetCaseId: caseId }),
      selectedSlideIds: prefetchedSlides ? getDefaultSelectedSlideIds(prefetchedSlides) : undefined,
    });
  };

  const { caseData: previousCaseData } = usePrefetchedCaseData({
    caseId: previousCaseId,
    studyId,
    enabled: !isNaN(previousCaseId),
  });
  const previousLinkTo = previousCaseId
    ? getCasePageUrl({ caseId: previousCaseId, prefetchedSlides: previousCaseData?.slides })
    : null;
  const { caseData: nextCaseData } = usePrefetchedCaseData({
    caseId: nextCaseId,
    studyId,
    enabled: !isNaN(nextCaseId),
  });
  const nextLinkTo = nextCaseId ? getCasePageUrl({ caseId: nextCaseId, prefetchedSlides: nextCaseData?.slides }) : null;

  const currentPage = caseIds ? Math.floor(findIndex(caseIds, (id) => id === currentCase?.id) / pageSize) : null;
  const totalPages = caseIds ? Math.floor(totalCases / pageSize) + 1 : 0;
  React.useEffect(() => {
    // If the current image's page is not filtered, update the filter
    if (
      !isLoadingCaseData &&
      currentPage !== null &&
      currentPage >= 0 &&
      currentPage < totalPages &&
      currentPage + 1 !== queryParams.page
    ) {
      setQueryParams({ page: currentPage + 1 });
    }
  }, [queryParams.page, currentPage, totalPages, isLoadingCaseData]);

  return (
    <BaseNavigationPanel
      nextLinkTo={nextLinkTo}
      previousLinkTo={previousLinkTo}
      isInitialLoading={isInitialLoadingCaseIds}
      navigationStageText={navigationStageText}
    />
  );
};
