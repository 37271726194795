import { useSignals } from '@preact/signals-react/runtime';
import { compact, forEach } from 'lodash';
import { useEffect } from 'react';
import { BooleanParam, useQueryParam } from 'use-query-params';

import {
  defaultLayerVisualizationSettings,
  LayerVisualizationSettings,
  slidesLayerVisualizationSettings,
  useApplyChangesToSlideLayerVisualizationSettings,
  useGetLayerSettingsFromUrl,
} from 'components/Procedure/Infobar/slidesVisualizationAndConfiguration';
import { FeatureMetadata } from 'components/Procedure/useSlideChannelsAndResults/featureMetadata';
import usePrevious from 'utils/usePrevious';
import { getRasterHeatmapUrlKey } from './rasterHeatmapHelpers';

export const useUpdateRasterHeatmapsSettingsOnChange = ({
  slideId,
  viewerIndex,
  stainTypeId,
  heatmaps,
}: {
  slideId: string;
  viewerIndex: number;
  stainTypeId: string;
  heatmaps: FeatureMetadata[];
}) => {
  useSignals();
  const [useDeckGL] = useQueryParam('useDeckGL', BooleanParam);
  const getLayerSettingsFromUrl = useGetLayerSettingsFromUrl();
  const applyChangesToSlideLayerVisualizationSettings = useApplyChangesToSlideLayerVisualizationSettings();

  const previousSlideParams = usePrevious({ slideId, viewerIndex });
  useEffect(() => {
    if (previousSlideParams?.slideId === slideId && previousSlideParams?.viewerIndex === viewerIndex) {
      return;
    }
    const viewerSlideLayerVisualizationSettings = slidesLayerVisualizationSettings?.[viewerIndex];
    const changes: Array<{
      layerId: string;
      layerUrlKey?: string;
      newSettings: Partial<LayerVisualizationSettings>;
    }> = [];
    forEach(compact(heatmaps), (parentHeatmap) => {
      forEach([parentHeatmap, ...(parentHeatmap?.nestedItems || [])], (heatmap) => {
        const layerSettingsKey = heatmap.id;
        const previousSettings =
          viewerSlideLayerVisualizationSettings?.value?.[slideId]?.[layerSettingsKey]?.value || {};
        const parentSettings: Partial<LayerVisualizationSettings> =
          viewerSlideLayerVisualizationSettings?.value?.[slideId]?.[parentHeatmap?.id]?.value || {};
        const layerUrlKey = getRasterHeatmapUrlKey(heatmap, parentHeatmap);
        const urlSettings = getLayerSettingsFromUrl({ layerUrlKey, stainTypeId, viewerIndex });
        changes.push({
          layerId: layerSettingsKey,
          newSettings: {
            ...defaultLayerVisualizationSettings,
            ...previousSettings,
            ...urlSettings,
            ...(parentSettings?.selected ? { selected: true } : {}),
            // OSD is buggy and has trouble with auto-selecting layers
            ...(!useDeckGL ? { selected: false } : {}),
          },
        });
      });
    });
    applyChangesToSlideLayerVisualizationSettings({
      slideId,
      viewerIndex,
      changes,
      stainTypeId,
    });
  }, [useDeckGL, viewerIndex, slideId, stainTypeId, heatmaps, getLayerSettingsFromUrl]);
};
