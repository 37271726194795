import { Permission } from 'interfaces/permissionOption';
import { Procedure } from 'interfaces/procedure';
import { Slide } from 'interfaces/slide';
import { compact, filter, find, first, forEach, includes, isEmpty, isEqual, map, slice, uniq } from 'lodash';
import { useCallback, useEffect, useMemo } from 'react';
import { BooleanParam, DelimitedArrayParam, StringParam, useQueryParam, withDefault } from 'use-query-params';
import { useEncodedFilters } from './useEncodedFilters';
import { usePermissions } from './usePermissions';
import { useStudyPlatformSettings } from './useStudyPlatformSettings';

const isPreciseStainMatch = (slide: Slide, stainTypes: string[]) =>
  Boolean(slide) && includes(stainTypes, slide.stainingType) && !slide.positiveControl && !slide.negativeControl;

export const useGetDefaultSelectedSlideIds = (studyId: string) => {
  const { hasPermission } = usePermissions();
  const isAccessionViewer = hasPermission(Permission.ViewAccessionDashboard);

  const hasTaggingPermissions = hasPermission(Permission.EditSlideTagsAssignments);
  const [slideTagsDrawerOpen] = useQueryParam('slideTagsDrawerOpen', BooleanParam);
  const [slideIdForReviewing] = useQueryParam('slideIdForReviewing', StringParam);

  const { defaultStainingType: defaultStainingTypeFromStudy, defaultSlideViewMode } = useStudyPlatformSettings(studyId);
  const displayOneSlideByDefault = defaultSlideViewMode === 'single';

  const { queryParams } = useEncodedFilters();
  const filteredStainTypes = queryParams?.filters?.stainTypes;
  const [viewersStainPreferences] = useQueryParam('viewersStainPreferences', DelimitedArrayParam);

  const [selectedSlideIds] = useQueryParam('selectedSlideIds', DelimitedArrayParam);

  return useCallback(
    (slides: Slide[]) => {
      const slideIdForReviewInCase = find(slides, { id: slideIdForReviewing })?.id;
      const isTaggingSlide = hasTaggingPermissions && slideTagsDrawerOpen && Boolean(slideIdForReviewInCase);
      // First get good defaults based on tagging and study settings
      const slidesWithFilteredStainTypes = uniq([
        ...filter(slides, (slide) => isPreciseStainMatch(slide, filteredStainTypes)),
        ...filter(slides, (slide) => includes(filteredStainTypes, slide.stainingType)),
      ]);

      const firstSlideIdWithDefaultStainTypeFromStudy = includes(filteredStainTypes, defaultStainingTypeFromStudy)
        ? find(slides, (slide) => isPreciseStainMatch(slide, [defaultStainingTypeFromStudy]))?.id ||
          find(slides, { stainingType: defaultStainingTypeFromStudy })?.id
        : undefined;

      const defaultFirstSlideId = isTaggingSlide
        ? // Use the slide for review as the first slide if the user is tagging a slide
          slideIdForReviewInCase
        : // otherwise use the first slide with the default stain type
          firstSlideIdWithDefaultStainTypeFromStudy ||
          // otherwise use the first slide with a stain type from the filters
          first(slidesWithFilteredStainTypes)?.id ||
          // otherwise use the first slide from the procedure
          first(slides)?.id;

      const defaultSecondSlideId =
        // Use the first slide with a stain type from the filters that is not the first slide
        find(slidesWithFilteredStainTypes, (slide) => slide.id !== defaultFirstSlideId)?.id ||
        // otherwise use the first slide from the procedure that is not the first slide
        find(slides, (slide) => slide.id !== defaultFirstSlideId)?.id;

      const defaultsWithoutViewerStainPreferences = [defaultFirstSlideId, defaultSecondSlideId];

      if (!isEmpty(viewersStainPreferences)) {
        // If the user has set their preferences, we will try to match them
        const defaultSlidesWithoutViewerStainPreferences = map(defaultsWithoutViewerStainPreferences, (id) =>
          find(slides, { id })
        );
        const matchingSlides: string[] = [];
        forEach(viewersStainPreferences, (stainType, viewerIndex) => {
          if (isTaggingSlide && viewerIndex === 0) {
            // If the user is tagging a slide, we want to make sure the slide for review is the first one
            matchingSlides.push(slideIdForReviewInCase);
            return;
          }
          // If a slide has already been selected and is in the procedure, we will keep it
          const currentlySelectedSlide = selectedSlideIds?.[viewerIndex];
          if (currentlySelectedSlide && includes(map(slides, 'id'), currentlySelectedSlide)) {
            matchingSlides.push(currentlySelectedSlide);
            return;
          }
          const findNewPreciseStainMatch = (slide: Slide) =>
            isPreciseStainMatch(slide, [stainType]) && !includes(matchingSlides, slide?.id);

          const findNewStainMatch = (slide: Slide) =>
            slide?.stainingType === stainType && !includes(matchingSlides, slide?.id);

          const matchingSlideFromDefaults = (
            find(defaultSlidesWithoutViewerStainPreferences, findNewPreciseStainMatch) ||
            find(defaultSlidesWithoutViewerStainPreferences, findNewStainMatch)
          )?.id;
          if (matchingSlideFromDefaults) {
            matchingSlides.push(matchingSlideFromDefaults);
          }
          const matchingSlideFromAllSlides = (find(slides, findNewPreciseStainMatch) || find(slides, findNewStainMatch))
            ?.id;

          if (matchingSlideFromAllSlides) {
            matchingSlides.push(matchingSlideFromAllSlides);
          }
        });
        if (!isEmpty(matchingSlides)) {
          return matchingSlides;
        }
      }
      // Otherwise, we will show the two default slides, unless the user is tagging a slide, there is only one slide, or the user has set the viewer to show one slide by default
      return !isTaggingSlide && (slides?.length ?? 0) > 1 && !isAccessionViewer && !displayOneSlideByDefault
        ? defaultsWithoutViewerStainPreferences
        : [first(defaultsWithoutViewerStainPreferences)];
    },
    [
      hasTaggingPermissions,
      slideTagsDrawerOpen,
      slideIdForReviewing,
      defaultStainingTypeFromStudy,
      filteredStainTypes,
      viewersStainPreferences,
      isAccessionViewer,
      displayOneSlideByDefault,
    ]
  );
};

/**
 * hook for managing the selectedSlideIds query param
 * @returns the current selectedSlideIds from the query param
 */
export const useSelectedSlideIds = (procedure: Procedure) => {
  const getDefaultSelectedSlideIds = useGetDefaultSelectedSlideIds(procedure?.studyId);

  const defaultSelectedSlideIds = useMemo(() => getDefaultSelectedSlideIds(procedure?.slides), [procedure?.slides]);

  // We try to start the viewer with good defaults, but will change the selectedSlideIds as needed to reflect study settings and user actions
  const SelectedSlideIdsParam = withDefault(DelimitedArrayParam, defaultSelectedSlideIds);
  return useQueryParam('selectedSlideIds', SelectedSlideIdsParam);
};

export const useSetSelectedSlideIds = (procedure: Procedure) => {
  const [selectedSlideIds, setSelectedSlideIdsParam] = useSelectedSlideIds(procedure);
  const [viewersStainPreferences, setViewersStainPreferences] = useQueryParam(
    'viewersStainPreferences',
    DelimitedArrayParam
  );

  const setSelectedSlideIds = useCallback(
    (newSetSelectedSlideIds: string[]) => {
      // Make sure the selected slides are in the procedure
      const slidesFromProcedure = compact(map(newSetSelectedSlideIds, (id) => find(procedure?.slides, { id })));
      const newSelectedSlides = map(slidesFromProcedure, 'id');
      setSelectedSlideIdsParam(
        (currentSelectedSlides) =>
          !isEqual(newSelectedSlides, currentSelectedSlides) ? newSelectedSlides : currentSelectedSlides,
        'replaceIn'
      );
      // Update the viewersStainPreferences based on the selected slides
      const newStainPreferences = map(slidesFromProcedure, 'stainingType');
      setViewersStainPreferences(
        (currentViewersStainPreferences) =>
          !isEqual(newStainPreferences, currentViewersStainPreferences)
            ? newStainPreferences
            : currentViewersStainPreferences,
        'replaceIn'
      );
    },
    [setSelectedSlideIdsParam, procedure?.slides]
  );

  const setFirstSlideId = (id: string) => {
    setSelectedSlideIds([id, ...slice(selectedSlideIds, 1)]);
  };

  return {
    selectedSlideIds,
    viewersStainPreferences,
    setSelectedSlideIds,
    setFirstSlideId,
  };
};

export const useSyncSelectedSlideIds = (procedure: Procedure) => {
  const getDefaultSelectedSlideIds = useGetDefaultSelectedSlideIds(procedure?.studyId);

  const { selectedSlideIds, setSelectedSlideIds, viewersStainPreferences } = useSetSelectedSlideIds(procedure);
  const { hasPermission } = usePermissions();

  const hasTaggingPermissions = hasPermission(Permission.EditSlideTagsAssignments);
  const [slideTagsDrawerOpen] = useQueryParam('slideTagsDrawerOpen', BooleanParam);
  const [slideIdForReviewing, setSlideIdForReviewing] = useQueryParam('slideIdForReviewing', StringParam);
  const slideIdForReviewInCase = find(procedure?.slides, { id: slideIdForReviewing })?.id;
  const isTaggingSlide = hasTaggingPermissions && slideTagsDrawerOpen && Boolean(slideIdForReviewInCase);

  const { isLoadingStudySettings } = useStudyPlatformSettings(procedure?.studyId);
  const [didApplyStudyStainPreferences, setDidApplyStudyStainPreferences] = useQueryParam(
    'didApplyStudyStainPreferences',
    BooleanParam
  );

  // Sync the selectedSlideIds query param when starting to tag a slide
  useEffect(() => {
    if (slideIdForReviewing && !slideIdForReviewInCase) {
      console.warn('Slide for reviewing not found in procedure slides', {
        slideIdForReviewing,
        slides: map(procedure?.slides, 'id'),
      });
      setSlideIdForReviewing(undefined, 'replaceIn');
    } else if (isTaggingSlide) {
      // If the user asked to tag a slide, make sure it is selected on the first viewer
      if (first(selectedSlideIds) === slideIdForReviewInCase) {
        return;
      } else if (includes(selectedSlideIds, slideIdForReviewInCase)) {
        setSelectedSlideIds([
          slideIdForReviewInCase,
          ...filter(selectedSlideIds, (id) => id !== slideIdForReviewInCase),
        ]);
      } else {
        setSelectedSlideIds([slideIdForReviewInCase, ...slice(selectedSlideIds, 1)]);
      }
    }
  }, [isTaggingSlide, slideIdForReviewInCase, selectedSlideIds]);

  useEffect(() => {
    if (!isLoadingStudySettings && !didApplyStudyStainPreferences && isEmpty(viewersStainPreferences)) {
      // Update selected slides, if needed, based on the study settings
      const newSelectedSlides = getDefaultSelectedSlideIds(procedure?.slides);
      if (
        !isEqual(newSelectedSlides, selectedSlideIds) &&
        // Only make the change if the slide for review is the first one if it is selected
        // Otherwise we will have a race between the useEffects
        (!slideIdForReviewInCase || first(newSelectedSlides) === slideIdForReviewInCase)
      ) {
        setSelectedSlideIds(newSelectedSlides);
      }
      setDidApplyStudyStainPreferences(true, 'replaceIn');
    } else if (isEmpty(selectedSlideIds)) {
      // If no slides are selected, set the default selected slides
      setSelectedSlideIds(getDefaultSelectedSlideIds(procedure?.slides));
    }
  }, [
    isLoadingStudySettings,
    didApplyStudyStainPreferences,
    viewersStainPreferences,
    selectedSlideIds,
    setSelectedSlideIds,
    procedure?.slides,
    slideIdForReviewInCase,
  ]);
};
