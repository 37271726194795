import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import DescriptionRoundedIcon from '@mui/icons-material/DescriptionRounded';
import RateReviewRoundedIcon from '@mui/icons-material/RateReviewRounded';
import { Box, Typography, useTheme } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { Annotation, AnnotationAssignment, AnnotationWorkingStates } from 'interfaces/annotation';
import { sum, values } from 'lodash';
import React, { useMemo } from 'react';
import AnnotationAssignmentToolbar from './AnnotationAssignmentTableToolbar';

interface AnnotationAssignmentTableProps {
  annotationAssignment: AnnotationAssignment;
}

const AnnotationAssignmentTable: React.FC<AnnotationAssignmentTableProps> = ({ annotationAssignment }) => {
  const theme = useTheme();
  const rows = annotationAssignment?.annotations;

  const dataGridSx = useMemo(
    () => ({
      display: 'grid',
      gridTemplateRows: 'auto 1f auto',
      '& .MuiDataGrid-columnHeaders': {
        backgroundColor: theme.palette.mode == 'light' ? theme.palette.grey[200] : theme.palette.grey[900],
        borderRadius: 0,
        borderTop: `1px solid ${theme.palette.mode == 'light' ? 'lightgrey' : 'rgb(81, 81, 81)'}`,
      },
      '.annotating': {
        color: theme.palette.primary.main,
      },
      '.qa': {
        color: theme.palette.info.main,
      },
      '.done': {
        color: theme.palette.success.main,
      },
    }),
    [theme]
  );

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ height: 400, width: '100%', overflowX: 'auto' }}>
        <DataGrid
          slots={{ toolbar: AnnotationAssignmentToolbar }}
          slotProps={{
            toolbar: annotationAssignment,
            pagination: {
              SelectProps: {
                sx: { width: 'auto' },
              },
            },
          }}
          getCellClassName={getCellClassName}
          rows={rows}
          columns={columns}
          getRowId={getRowId}
          sx={dataGridSx}
          density="compact"
        />
      </Box>
    </Box>
  );
};

function getRowId(row: Annotation) {
  return row.annotationId;
}

function getCellClassName(params: { field: string; row: Annotation }) {
  if (params.field === 'workingState') {
    const state: AnnotationWorkingStates = params.row.workingState as AnnotationWorkingStates;
    return state === AnnotationWorkingStates.Annotating
      ? 'annotating'
      : state === AnnotationWorkingStates.QA
      ? 'qa'
      : 'done';
  }
  return '';
}

const renderStatusCell: GridColDef['renderCell'] = (params) => {
  const state: AnnotationWorkingStates = params.row.workingState;
  return (
    <>
      {state === AnnotationWorkingStates.Annotating ? (
        <DescriptionRoundedIcon />
      ) : state === AnnotationWorkingStates.QA ? (
        <RateReviewRoundedIcon />
      ) : (
        <CheckCircleRoundedIcon />
      )}
      <Typography sx={{ ml: 1 }}>{state}</Typography>
    </>
  );
};

const columns: GridColDef[] = [
  { field: 'slideId', headerName: 'Slide ID', width: 350 },
  {
    field: '# of Annotations',
    headerName: '# of Annotations',
    width: 200,
    type: 'number',
    align: 'left',
    headerAlign: 'left',
    valueGetter: (params) => sum(values(params.row.annotationSummary)),
  },
  {
    field: 'tagger',
    headerName: 'Assignee',
    width: 200,
    valueGetter: (params) => params.row.tagger?.name,
  },
  {
    field: 'workingState',
    headerName: 'Status',
    width: 200,
    renderCell: renderStatusCell,
  },
];

export default AnnotationAssignmentTable;
