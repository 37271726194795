import { Box, Grid, List, ListItemButton, ListItemText, Skeleton, Tooltip, Typography } from '@mui/material';
import { NON_SPECIFIC_STUDY_VALUES } from 'components/SearchFilters/hooks/useMainFilters';
import { Permission } from 'interfaces/permissionOption';
import { includes, map } from 'lodash';
import React, { useRef } from 'react';
import { useAnnotationAssignments } from 'utils/useAnnotationAssignments';
import { usePermissions } from 'utils/usePermissions';
import AnnotationAssignmentView from './AnnotationAssignmentView';

interface AnnotationAssignmentViewProps {
  studyId: string;
}

const AnnotationAssignmentsView: React.FC<AnnotationAssignmentViewProps> = ({ studyId }) => {
  const { hasPermission } = usePermissions();
  const isSpecificStudySelected = studyId && !includes(NON_SPECIFIC_STUDY_VALUES, studyId.toString());
  const canViewAnnotationAssignments = hasPermission(Permission.ViewAnnotationsList) && isSpecificStudySelected;

  const { data: annotationAssignments, isLoading, isError } = useAnnotationAssignments(studyId);

  const sectionRefs = useRef<{ [key: number]: HTMLElement | null }>({});
  const scrollToSection = (annotationAssignmentId: number) => {
    sectionRefs.current[annotationAssignmentId]?.scrollIntoView({
      behavior: 'smooth',
    });
  };

  if (!isSpecificStudySelected) {
    return (
      <Box height={300}>
        <Typography variant="h4">Please select a specific study to view annotation assignments</Typography>
      </Box>
    );
  }

  // if some how the user got to this page without the correct permissions
  if (!canViewAnnotationAssignments) {
    return (
      <Box height={300}>
        <Typography variant="h4">You do not have permission to view this page</Typography>
      </Box>
    );
  }

  if (isError) {
    return (
      <Box height={300}>
        <Typography variant="h4">Failed to load annotation assignments, please contact support</Typography>
      </Box>
    );
  }

  return (
    <Grid item container direction="row" spacing={2} height="100%" m={0}>
      <Grid item xs={3} height="100%" sx={{ overflowY: 'auto', paddingTop: 2 }}>
        {isLoading ? (
          <ListSkeleton />
        ) : (
          <List component="nav">
            {map(annotationAssignments, (annotationAssignment) => (
              <ListItemButton
                key={annotationAssignment.annotationAssignmentId}
                onClick={() => scrollToSection(annotationAssignment.annotationAssignmentId)}
              >
                <Tooltip title={annotationAssignment.name} arrow enterDelay={750} enterNextDelay={750}>
                  <ListItemText
                    primary={annotationAssignment.name}
                    primaryTypographyProps={{
                      style: {
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                      },
                    }}
                  />
                </Tooltip>
              </ListItemButton>
            ))}
          </List>
        )}
      </Grid>
      <Grid item xs={true} height="100%" sx={{ overflowY: 'auto', paddingTop: 2 }}>
        <Grid container direction="column" spacing={3} px={1}>
          {isLoading ? (
            <AssignmentsTablesSkeleton />
          ) : (
            map(annotationAssignments, (annotationAssignment) => {
              return (
                <Grid
                  item
                  key={annotationAssignment.annotationAssignmentId}
                  ref={(el) => (sectionRefs.current[annotationAssignment.annotationAssignmentId] = el)}
                >
                  <AnnotationAssignmentView annotationAssignment={annotationAssignment} />
                </Grid>
              );
            })
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

function ListSkeleton() {
  return (
    <Box height={300}>
      <Skeleton variant="text" sx={{ fontSize: '1rem', width: '60%' }} />
      <Skeleton variant="text" sx={{ fontSize: '1rem', width: '60%' }} />
      <Skeleton variant="text" sx={{ fontSize: '1rem', width: '60%' }} />
    </Box>
  );
}

function AssignmentsTablesSkeleton() {
  return (
    <Grid container spacing={1} direction="column" mt={1}>
      <Grid item>
        <Skeleton variant="text" sx={{ fontSize: '1rem', width: '60%' }} />
      </Grid>
      <Grid item>
        <Skeleton variant="rectangular" width="100%" height={200} />
      </Grid>
      <Grid item>
        <Skeleton variant="text" sx={{ fontSize: '1rem', width: '60%' }} />
      </Grid>
      <Grid item>
        <Skeleton variant="rectangular" width="100%" height={200} />
      </Grid>
    </Grid>
  );
}

export default AnnotationAssignmentsView;
